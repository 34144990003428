<template>
    <div class="app-container">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" filterable  clearable @change="handleInstitution" placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                                   :value="item.institutionId"></el-option>
                    </el-select>
<!--                    <el-input placeholder="请输入单位名称" v-model="search.institutionName" size='medium' class="form-line-item"></el-input>-->
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                               class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                   :value="item.batchId"></el-option>
                    </el-select>
<!--                    <el-input placeholder="请输入批次名称" v-model="search.batchName" size='medium' class="form-line-item"></el-input>-->
                </el-form-item>
                <el-form-item label="归档:">
                    <el-select v-model="search.isFiled" size='medium' clearable placeholder="请选择是否归档"
                               class="form-line-item"
                               ref="selectIsFiled"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in isFiledList" :key='item.key' :label="item.value"
                                   :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="getBatchDataList">
                            搜索
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                            重置
                    </el-button>
                </el-form-item>
            </el-form>

        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>

                    <el-button type="success" size="small" icon="el-icon-download" @click="updateBatchIsFiled(1)">归档
                    </el-button>
                    <el-button type="success" size="small" icon="el-icon-download" @click="updateBatchIsFiled(0)">取消归档
                    </el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                       highlight-hover-row align="center" ref="myTable" :data="batchDataList"
                       class="mytable-scrollbar financialList" show-footer

                       :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                       :loading='loading'>
<!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
                <vxe-column type='checkbox' title="全选" width="6%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位名称" width="30%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchName" title="批次名称" width="30%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="createTime" title="创建时间" width="10%" :show-overflow="'tooltip'" sortable></vxe-column>
                <vxe-column field="fileDatetime" title="归档时间" width="10%" :show-overflow="'tooltip'" sortable>
<!--                    <template #default="{ row }">-->
<!--                        {{row.fileDatetime!=null?row.fileDatetime:'未归档'}}-->
<!--                    </template>-->
                </vxe-column>
                <vxe-column field="isFiled" title="归档状态" width="14%" :show-overflow="'tooltip'" sortable>
                    <template #default="{ row }">
                        {{row.isFiled==1?'已归档':'未归档'}}
                    </template>
                </vxe-column>

            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                       :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                       :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                       @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 上传打款表 -->

    </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
    name: 'unpay',
    data() {
        return {
            uploadModel: false,
            loading: false,


            isFiledList:[
                {key:0,value:"未归档"},
                {key:1,value:"已归档"},
            ],
            batchShow: true,
            institutionList: [],
            batchList: [],
            batchDataList: [],
            search: {
                institutionId:'',
                batchId:"",
                institutionName: '',
                batchName: '',
                isFiled: '',

            },
            // 分页
            page: {
                currentPage: 1,
                pageSize: 10,
                pageCount: 40, //自己用
                pageSizes: [10, 20, 50, 100, 200],
                total: 0
            },

        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
        this.getInstitution();
        this.getBatchDataList();

    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.getBatchDataList();
            }
        },

        //表格刷新
        refresh() {
            this.getBatchDataList();
        },
        //获取送审单位
        getInstitution() {
            // let params = new URLSearchParams();
            // params.append("isFiled", null);
            let params = {

                isFiled: null
            }
            console.log(params);
            this.$api.schoolCustom.getSendingInstitution(params)
                .then(res => {
                    console.log(res);
                    this.institutionList = res.data.data;
                })
                .catch(err => {
                    this.$message.warning('接口错误');
                })
        },
        //获取送审单位的批次
        getBatch(institutionId) {
            // let params = new URLSearchParams();
            // params.append("institutionId", institutionId);
            // params.append("isFiled",null);

            let params = {
                institutionId:institutionId,
                isFiled: ""
            }
            console.log(params)
            this.$api.sendingP2p.getBatch(params)
                .then(res => {
                    console.log(res.data.data);
                    this.batchList = res.data.data;
                })
                .catch(err => {
                    this.$message.warning("服务器维护");
                });
        },
        //机构选择改变
        handleInstitution(val) {
            this.search.batchId = '';
            if (val == '') {
                this.batchShow = true;
                return;
            }
            this.batchShow = false;
            this.getBatch(val);
        },
        getBatchDataList() {
            this.loading = true;
            // let param = new URLSearchParams();
            // param.append("institutionName", this.search.institutionName);
            // param.append("batchName", this.search.batchName);
            // param.append("isFiled", this.search.isFiled);
            //
            // param.append("pageNum", this.page.currentPage);
            // param.append("pageSize", this.page.pageSize);
            let param ={
                // institutionName:this.search.institutionName,
                // batchName:this.search.batchName,
                institutionId:this.search.institutionId,
                batchId:this.search.batchId,
                isFiled:this.search.isFiled,
                pageNum:this.page.currentPage,
                pageSize:this.page.pageSize,
            };
            // console.log(param)
            this.$api.batchManagement.getBatchDataList(param)
                .then(res => {
                    console.log(res);
                    this.batchDataList = res.data.data;
                    this.page.total = res.data.count;
                    this.loading = false;
                }).catch(err => {
                this.$message.warning("服务器维护！");
            });
        },
        updateBatchIsFiled(status){
            // 得到所有checkbox
            let ids = [];
            // console.log(this.$refs.myTable.getCheckboxRecords())
            let checkBox = this.$refs.myTable.getCheckboxRecords();
            for(let i=0;i<checkBox.length;i++){
                ids.push(checkBox[i].batchId);
            }
            let param = {
                isFiled:status,
                batchIds:ids,
            }
            this.$api.batchManagement.updateBatchIsFiled(param)
                .then(res => {
                    console.log(res.data.msg);
                    let msg = res.data.msg;
                    this.$message.success({
                        dangerouslyUseHTMLString: true,
                        message: `<strong>${msg}</strong>`
                    });
                    this.refresh();

                }).catch(err => {
                this.$message.warning("服务器维护！");
            });
        },
        resetHandle() {
            this.search = {
                institutionId:'',
                batchId: "",
                institutionName: '',
                batchName: '',
                isFiled:'',
            }
        },
        getFileName(str) {
            return str.substr(str.indexOf('\\') + 1)
        },

        //分页操作
        handlePageChange({
                             currentPage,
                             pageSize
                         }) {
            console.log(currentPage, pageSize);
            this.page.currentPage = currentPage;
            this.page.pageSize = pageSize;
            this.getBatchDataList();
        },
        //每页多少数据改变
        handleChangePageSize(val) {
            this.page.pageSize = val;
            this.page.currentPage = 1;
            this.getBatchDataList();
        },
        firstPage() {
            this.page.currentPage = 1;
            this.getBatchDataList();
        },

        //头部样式
        headerCellStyle({
                            row,
                            column,
                            rowIndex,
                            columnIndex
                        }) {
            return "background-color:#e0e0e0f1"
        },
        //设置单元格样式
        cellStyle({
                      row,
                      rowIndex,
                      column,
                      columnIndex
                  }) {
            //设置状态颜色
            let styles = {};
            if (column.property == 'payStatue') {
                styles['color'] = this.payStatue[row.payStatue].color;
            }
            return styles;
        },
        dateFormat: function (time) {
            var datetime = new Date(time);
            // datetime.setTime(time);
            var year = datetime.getFullYear();
            var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime
                    .getMonth() +
                1;
            var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
            return year + "-" + month + "-" + date;
        },
        isShowSelectOptions(isShowSelectOptions){
            if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
            if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
            if(!isShowSelectOptions) this.$refs.selectIsFiled.blur();

        },
    },
    components: {

    }
}
</script>

<style scoped>
.form-line-item {
    width: 15em;
}

.green {
    color: green;
}

.red {
    color: red;
}

.el-dialog__body {
    padding-bottom: 0px;
}

.yzmBtn {
    margin-left: 5px;
}
.el-form-item {
    margin-bottom: 10px !important;
}
</style>

<style>
.financialList .vxe-table--body-wrapper {
    height: calc(100vh - 390px);
}
</style>